import { FormikProvider, useFormik } from 'formik';
import React, { FC, useDeferredValue, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { array, lazy, object, string } from 'yup';
import {
  ApiError,
  CommunicationChannelsService,
  PostbackDto,
  PostbackParamDto,
} from '../../_generatedApi';
import { setChannels } from '../../store/communication-channels/channelSlice';
import CommonModal from '../Modal/Modal';
import ModalFooter from '../Modal/ModalFooter';
import { PostbackForm, PostbackParamData } from './PostbackForm';
import action = PostbackDto.action;
import { Button, CircularProgress } from '@mui/material';
import parseBusinessApiError from '../../utils/parse-business-api-error';
import { setSnackbarOpen } from '../../store/common/snackbarSlice';
import { INPUT_SIMPLE_CODE } from '../../consts';

interface IPostbackModal {
  open: boolean;
  channelId: string;
  setOpenModal: (open: boolean) => void;
}

const PostbackModal: FC<IPostbackModal> = (props) => {
  const { open, setOpenModal, channelId } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [detailLoaded, setDetailLoaded] = useState(false);
  const [utmParamsLoaded, setUtmParamsLoaded] = useState(false);
  const [isNewPostback, setIsNewPostback] = useState(false);

  const deferredDetailLoaded = useDeferredValue(
    detailLoaded && utmParamsLoaded,
  );

  const [postbackDetail, setPostbackDetail] = useState<PostbackForm>({
    url: '',
    notify: false,
    action: action.POST,
    postbackParams: [],
    monitoredStates: [],
  });

  const [utmParamsIdToNameMap, setUtmParamsIdToNameMap] = useState<
    Map<string, string>
  >(new Map<string, string>());

  useEffect(() => {
    CommunicationChannelsService.getUtmParams()
      .then((response) => {
        const newMap = new Map(response.data.map((v) => [v.id, v.name]));
        setUtmParamsIdToNameMap(newMap);
        setUtmParamsLoaded(true);
      })
      .catch(() => {
        setUtmParamsLoaded(true);
        dispatch(
          setSnackbarOpen({
            message: `communicationChannels.postbackModal.loadingError`,
            severity: 'error',
          }),
        );
      });

    if (!channelId) {
      setIsNewPostback(true);
      return;
    }

    CommunicationChannelsService.findPostback({ id: channelId })
      .then((res) => {
        setPostbackDetail({
          ...res.data,
          postbackParams: res.data.postbackParams?.map((p) => {
            return {
              ...p,
              value:
                p.type === PostbackParamDto.type.STATIC_VALUE
                  ? p.staticValue
                  : p.type === PostbackParamDto.type.DYNAMIC_VALUE
                  ? p.dynamicValue
                  : p.utmParam?.id,
            } as PostbackParamData;
          }),
        });
        setDetailLoaded(true);
        setIsNewPostback(false);
      })
      .catch((error) => {
        setIsNewPostback(true);
        setDetailLoaded(true);
        const code = (error as ApiError).status;
        if (code === 404) {
          return;
        }

        dispatch(
          setSnackbarOpen({
            message: `communicationChannels.postbackModal.loadingError`,
            severity: 'error',
          }),
        );
      });
  }, []);

  const onSubmit = async (values: PostbackForm) => {
    try {
      const cleanValues = {
        ...values,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars,unused-imports/no-unused-vars
        postbackParams: values.postbackParams.map(({ value, ...rest }) => ({
          ...rest,
        })),
      };
      await CommunicationChannelsService.setPostback({
        id: String(channelId),
        requestBody: cleanValues,
      });
      const result = await CommunicationChannelsService.findAll();
      dispatch(setChannels(result.data));
      dispatch(
        setSnackbarOpen({
          message: 'communicationChannels.response.postback.Ok',
          severity: 'success',
        }),
      );
      void handleClose();
    } catch (error) {
      dispatch(
        setSnackbarOpen({
          message: `communicationChannels.response.postback.${
            (error as ApiError).status
          }`,
          severity: 'error',
        }),
      );
    }
  };

  const validationSchema = object({
    url: string()
      .required(String(t('communicationChannels.postbackModal.urlRequired')))
      .url(String(t('communicationChannels.postbackModal.urlWrongFormat'))),
    monitoredStates: array().min(
      1,
      String(t('communicationChannels.postbackModal.atleastOneMonitoredState')),
    ),
    postbackParams: array().of(
      object().shape({
        name: string()
          .required(
            String(t('communicationChannels.postbackModal.nameRequired')),
          )
          .matches(
            INPUT_SIMPLE_CODE,
            String(
              t('communicationChannels.postbackModal.nameAllowedCharacters'),
            ),
          ),
        type: string().required(
          String(t('communicationChannels.postbackModal.typeRequired')),
        ),
        staticValue: lazy((value, { parent: { type } }) => {
          if (type === PostbackParamDto.type.STATIC_VALUE) {
            return string()
              .required(
                String(t('communicationChannels.postbackModal.valueRequired')),
              )
              .matches(
                INPUT_SIMPLE_CODE,
                String(
                  t(
                    'communicationChannels.postbackModal.valueAllowedCharacters',
                  ),
                ),
              );
          }
          return string();
        }),
        dynamicValue: lazy((value, { parent: { type } }) => {
          if (type === PostbackParamDto.type.DYNAMIC_VALUE) {
            return string().required(
              String(t('communicationChannels.postbackModal.valueRequired')),
            );
          }
          return string().nullable();
        }),
        utmParam: lazy((value, { parent: { type } }) => {
          if (type === PostbackParamDto.type.UTM_VALUE) {
            return object().shape({
              id: string().required(
                String(t('communicationChannels.postbackModal.valueRequired')),
              ),
              name: string().required(
                String(t('communicationChannels.postbackModal.valueRequired')),
              ),
            });
          }
          return object().optional();
        }),
        value: lazy((value, { parent: { type } }) => {
          if (
            [
              PostbackParamDto.type.DYNAMIC_VALUE,
              PostbackParamDto.type.UTM_VALUE,
            ].includes(type)
          ) {
            return string().required(
              String(t('communicationChannels.postbackModal.valueRequired')),
            );
          }
          if (type === PostbackParamDto.type.STATIC_VALUE) {
            return string()
              .required(
                String(t('communicationChannels.postbackModal.valueRequired')),
              )
              .matches(
                INPUT_SIMPLE_CODE,
                String(
                  t(
                    'communicationChannels.postbackModal.valueAllowedCharacters',
                  ),
                ),
              );
          }
          return string();
        }),
      }),
    ),
  });

  const postbackForm = useFormik({
    initialValues: postbackDetail as PostbackForm,
    validationSchema,
    enableReinitialize: true,
    onSubmit,
  });

  const handleClose = async () => {
    setOpenModal(false);
  };

  const testPostbackConnection = async () => {
    try {
      await CommunicationChannelsService.testConnection({ id: channelId });
      dispatch(
        setSnackbarOpen({
          message: 'communicationChannels.response.testConnection.Ok',
          severity: 'success',
        }),
      );
    } catch (e) {
      const error = e as ApiError;
      dispatch(
        setSnackbarOpen({
          message: `communicationChannels.response.testConnection.${parseBusinessApiError(
            error,
          )}`,
          severity: 'error',
        }),
      );
    }
  };

  if (!deferredDetailLoaded) return <CircularProgress />;

  return (
    <FormikProvider value={postbackForm}>
      <CommonModal
        dataCy="createEditDialog"
        open={open}
        onClose={handleClose}
        title={t('communicationChannels.postbackModal.setPostbacking')}
        content={
          <PostbackForm
            detailLoaded={deferredDetailLoaded}
            utmParamsIdToNameMap={utmParamsIdToNameMap}
          />
        }
        isNew={isNewPostback}
        footer={
          <ModalFooter
            isNew={isNewPostback}
            onSubmit={postbackForm.submitForm}
            onClose={handleClose}
            customButtons={
              !isNewPostback ? (
                <Button
                  variant="contained"
                  onClick={testPostbackConnection}
                  data-cy="postbackTestButton"
                >
                  {t('communicationChannels.postbackModal.testConnection')}
                </Button>
              ) : undefined
            }
          />
        }
        modalWidth={'700px'}
      />
    </FormikProvider>
  );
};

export default PostbackModal;
