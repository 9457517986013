/* eslint-disable react/prop-types */
import { useDispatch } from 'react-redux';
import React, { Suspense, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BudgetDetailDto, BudgetDto, BudgetsService } from '../_generatedApi';
import AlertDialog from '../components/AlertDialog';
import { IGridRowAction } from '../components/gridTable';
import { removeBudget, setBudgets } from '../store/budget/budgetSlice';
import BudgetModal, {
  BudgetModalMode,
} from '../components/budgets/BudgetModal';
import { setSnackbarOpen } from '../store/common/snackbarSlice';
import { Datatable, Button, datatableColumnsHelper } from '@flexifincz/ui';
import type { DatatableColumns } from '@flexifincz/ui';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';

const TRANSLATION_PREFIX = 'budgets';

const filterByYearAndMonth = (columnValue: Dayjs, filterValue: Dayjs) => {
  const fromFilter = filterValue.format('YYYY-MM');
  return dayjs(fromFilter).isSame(columnValue);
};

const Budgets = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [selectedBudget, setSelectedBudget] = useState<BudgetDto>();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [budgetId, setBudgetId] = useState<string | undefined>(undefined);
  const [modalMode, setModalMode] = useState<BudgetModalMode>(
    BudgetModalMode.CREATE,
  );

  const actions: IGridRowAction = {
    onEdit: (params: unknown) => {
      setModalMode(BudgetModalMode.UPDATE);
      setOpenEditModal(true);
      setBudgetId((params as BudgetDetailDto).id);
    },
    onCopy: (params: unknown) => {
      setModalMode(BudgetModalMode.COPY);
      setOpenEditModal(true);
      setBudgetId((params as BudgetDetailDto).id);
    },
    onDelete: (params: unknown) => {
      setSelectedBudget(params as BudgetDto);
      setOpenDeleteDialog(true);
    },
  };

  const deleteBudget = (): void => {
    if (!selectedBudget?.id) {
      return;
    }

    void BudgetsService.remove({ id: selectedBudget.id })
      .then(() => {
        dispatch(removeBudget(selectedBudget.id));
        dispatch(
          setSnackbarOpen({
            message: 'budgets.response.delete.Ok',
            severity: 'success',
          }),
        );
      })
      .catch((error) => {
        dispatch(
          setSnackbarOpen({
            message: `budgets.response.delete.${error.status}`,
            severity: 'error',
          }),
        );
      });
    setOpenDeleteDialog(false);
  };

  const openNewModal = () => {
    setModalMode(BudgetModalMode.CREATE);
    setOpenEditModal(true);
    setBudgetId(undefined);
  };

  const columns = useMemo<DatatableColumns<BudgetDto>>(
    () =>
      datatableColumnsHelper([
        {
          accessorKey: 'displayName',
          header: t(`${TRANSLATION_PREFIX}.displayName`),
        },
        {
          accessorFn: (row) => dayjs(row.validFrom).format('YYYY-MM'),
          id: 'validFrom',
          filterVariant: 'date',
          filterFn: (row, columnId, filterValue) =>
            filterByYearAndMonth(row.getValue(columnId), filterValue as Dayjs),
          header: t(`${TRANSLATION_PREFIX}.validFrom`),
          Cell: ({ row }) => dayjs(row.original.validFrom).format('YYYY-MM'),
          enableGlobalFilter: false,
          size: 150,
        },
        {
          accessorFn: (row) => dayjs(row.validTo).format('YYYY-MM'),
          id: 'validTo',
          filterVariant: 'date',
          filterFn: (row, columnId, filterValue) =>
            filterByYearAndMonth(row.getValue(columnId), filterValue as Dayjs),
          header: t(`${TRANSLATION_PREFIX}.validTo`),
          Cell: ({ row }) =>
            row.original.validTo
              ? dayjs(row.original.validTo).format('YYYY-MM')
              : '',
          enableGlobalFilter: false,
          size: 150,
        },
        {
          accessorKey: 'channel.name',
          header: t(`${TRANSLATION_PREFIX}.channel.name`),
          variety: 'identifier',
        },
        {
          accessorKey: 'affiliatePartner.name',
          header: t(`${TRANSLATION_PREFIX}.affiliatePartner.name`),
        },
        {
          accessorKey: 'costPerLead',
          header: t(`${TRANSLATION_PREFIX}.costPerLead`),
          variety: 'number',
          size: 130,
        },
        {
          accessorKey: 'limit',
          header: t(`${TRANSLATION_PREFIX}.limit`),
          variety: 'number',
          size: 130,
        },
        {
          accessorKey: 'overdraftEnabled',
          header: t(`${TRANSLATION_PREFIX}.overdraftEnabled`),
          variety: 'check',
        },
      ]),
    [t],
  );

  return (
    <>
      <AlertDialog
        state={openDeleteDialog}
        stateCallback={setOpenDeleteDialog}
        buttonAction={deleteBudget}
        title={t('budgets.dialogAlert.title')}
        message={
          t('budgets.dialogAlert.message', {
            budgetName: selectedBudget?.displayName,
          }) as string
        }
        buttonText={t('budgets.dialogAlert.button')}
        dataCy={'deleteDialog'}
      />
      <Datatable
        columns={columns}
        id="budgets"
        defaultSort={[{ id: 'displayName', desc: false }]}
        muiFilterDatePickerProps={{
          format: 'YYYY-MM',
          views: ['year', 'month'],
        }}
        dataProvider={async () => {
          const response = await BudgetsService.findAll();

          dispatch(setBudgets(response.data));

          return {
            data: response.data,
          };
        }}
        language={i18n.language}
        toolbarActions={() => (
          <Button
            variety="create"
            data-cy="addButton"
            onClick={() => openNewModal()}
            text={t('grid.newRow')}
          />
        )}
        rowActions={({ row }) => (
          <>
            <Button
              variety="edit"
              data-cy="editButton"
              text={t('grid.edit')}
              circle
              onClick={() => actions.onEdit && actions.onEdit(row.original)}
            />
            <Button
              variety="copy"
              data-cy="copyButton"
              text={t('grid.copy')}
              circle
              onClick={() => actions.onCopy && actions.onCopy(row.original)}
            />
            <Button
              variety="delete"
              data-cy={`deleteButton-${row.original.id}`}
              text={t('grid.delete')}
              circle
              onClick={() => actions.onDelete && actions.onDelete(row.original)}
            />
          </>
        )}
      />
      {openEditModal && (
        <Suspense fallback="Loading...">
          <BudgetModal
            setOpenModal={setOpenEditModal}
            open={openEditModal}
            budgetId={budgetId}
            mode={modalMode}
          />
        </Suspense>
      )}
    </>
  );
};

export default Budgets;
