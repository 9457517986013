import { CellValueChangedEvent } from '@ag-grid-community/core';
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  MenuItem,
  Stack,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { Field, useFormikContext } from 'formik';
import { Select, Switch } from 'formik-mui';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CreatePostbackDto,
  PostbackDto,
  PostbackParamDto,
} from '../../_generatedApi';
import FormikChipMultiSelect from '../form/FormikChipMultiSelect';
import { GridTable } from '../gridTable';
import {
  ColumnDefitions,
  GridColumnType,
  IGridRowAction,
} from '../gridTable/types';
import type = PostbackParamDto.type;
import { TextField } from '../form/FormikTextField';

const MONITORED_STATES = [
  'LEAD_ACQUIRED',
  'LOAN_REJECTED',
  'FULL_APPLICATION',
  'LOAN_ISSUED',
];

export const DYNAMIC_VALUES = Object.values(PostbackParamDto.dynamicValue);

export interface PostbackParamData {
  index: number;
  name: string;
  type: PostbackParamDto.type;
  value: string | PostbackParamDto.dynamicValue;
}

interface IPostbackForm {
  detailLoaded: boolean;
  utmParamsIdToNameMap: Map<string, string>;
}

export type PostbackParams = PostbackParamDto & {
  value?: string | PostbackParamDto.dynamicValue;
};

export type PostbackForm = Omit<CreatePostbackDto, 'postbackParams'> & {
  postbackParams: Array<PostbackParams>;
};

export const PostbackForm: FC<IPostbackForm> = ({
  detailLoaded,
  utmParamsIdToNameMap,
}: IPostbackForm) => {
  const { t } = useTranslation();
  const { values, setFieldValue, errors } = useFormikContext<PostbackForm>();

  const postbackParamColumns: ColumnDefitions = {
    type: {
      fieldType: GridColumnType.String,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ['STATIC_VALUE', 'DYNAMIC_VALUE', 'UTM_VALUE'],
      },
      editable: true,
      valueFormatter: (params) => {
        return t(`communicationChannels.postbackModal.${params.value}`);
      },
    },
    name: {
      fieldType: GridColumnType.String,
      editable: true,
    },
    value: {
      fieldType: GridColumnType.String,
      valueFormatter: (params) => {
        //formatting for agSelectCell ↓  - no identifier to distinguish between utm and other params available
        if (!params?.data) {
          return utmParamsIdToNameMap.get(params.value) || params?.value;
        }
        //display name for utm param id
        if (params?.data?.type === PostbackParamDto.type.UTM_VALUE) {
          return utmParamsIdToNameMap.get(params.value) || '';
        }
        return params?.value;
      },

      cellEditorSelector: (params: { data: PostbackParamData }) => {
        const { type } = params.data;

        if (type === PostbackParamDto.type.DYNAMIC_VALUE) {
          return {
            component: 'agSelectCellEditor',
            params: { values: DYNAMIC_VALUES },
            popup: false,
            editable: true,
          };
        }
        if (type === PostbackParamDto.type.UTM_VALUE) {
          return {
            component: 'agSelectCellEditor',
            params: {
              values: utmParamsIdToNameMap.keys(),
            },
            popup: false,
            editable: true,
          };
        }

        return {
          component: 'agTextCellEditor',
          editable: true,
          popup: false,
        };
      },
      editable: true,
    },
  };

  const onCreatePostbackParam = () => {
    void setFieldValue('postbackParams', [
      ...values.postbackParams,
      {
        name: '',
        staticValue: '',
        type: PostbackParamDto.type.STATIC_VALUE,
        dynamicValue: PostbackParamDto.dynamicValue.REF_NO,
        value: '',
        utmParam: undefined,
      },
    ]);
  };

  const onUpdatePostbackParam = () => {
    const params = values.postbackParams.map((param) => {
      const postbackParamDto: PostbackParamDto = {
        name: param.name,
        type: param.type,
      };

      if (param.type === PostbackParamDto.type.STATIC_VALUE && param.value) {
        postbackParamDto.staticValue = String(param.value);
      } else if (
        param.type === PostbackParamDto.type.DYNAMIC_VALUE &&
        param.value
      ) {
        postbackParamDto.dynamicValue =
          param.value as PostbackParamDto.dynamicValue;
      } else {
        postbackParamDto.utmParam = {
          id: String(param.value),
          name: utmParamsIdToNameMap.get(String(param.value)) || '',
        };
      }

      return postbackParamDto;
    });
    void setFieldValue('postbackParams', params);
  };

  const postbackParamActions: IGridRowAction = {
    onDelete: (params: unknown, rowIndex?: number) => {
      if (rowIndex === undefined || !values.postbackParams) {
        return;
      }

      const newPostbackParams = values.postbackParams.filter(
        (_, index) => index !== rowIndex,
      );
      void setFieldValue('postbackParams', newPostbackParams);
    },
  };

  const onCellValueChanged = (e: CellValueChangedEvent<unknown, unknown>) => {
    const event = e as CellValueChangedEvent<PostbackParamData, unknown>;
    if (event.rowIndex === null) {
      return;
    }

    const { value, ...rest } = event.data;
    const updatedParam = values.postbackParams[event.rowIndex];
    Object.assign(updatedParam, rest);
    //change of param type - empty value for static, first possible value for multiple choice types
    const changedColumn = e.column.getColId();
    if (changedColumn === 'type') {
      if (e.newValue === PostbackParamDto.type.STATIC_VALUE) {
        updatedParam.value = '';
      } else if (e.newValue === PostbackParamDto.type.DYNAMIC_VALUE) {
        updatedParam.value = PostbackParamDto.dynamicValue.REF_NO;
      } else {
        updatedParam.value = utmParamsIdToNameMap.keys().next().value || '';
      }
      void setFieldValue('postbackParams', values.postbackParams);
      return;
    }
    //param value or name has changed
    if (updatedParam.type === type.DYNAMIC_VALUE) {
      updatedParam.dynamicValue = value as PostbackParamDto.dynamicValue;
    } else if (updatedParam.type === PostbackParamDto.type.STATIC_VALUE) {
      updatedParam.staticValue = String(value);
    } else {
      updatedParam.utmParam = {
        id: String(updatedParam.value),
        name: utmParamsIdToNameMap.get(String(updatedParam.value)) || '',
      };
    }
    updatedParam.value = value;
    void setFieldValue('postbackParams', values.postbackParams);
  };

  if (!detailLoaded) return <CircularProgress />;

  return (
    <>
      <Stack direction={'row'} spacing={2}>
        <Field
          component={TextField}
          name="url"
          label={t('communicationChannels.postbackModal.url')}
          required={true}
          sx={{ width: 3 / 5 }}
          inputProps={{
            'data-cy': `postbackUrl`,
          }}
        />
        <FormControl sx={{ width: 1 / 5 }} data-cy="postbackMethod">
          <Field
            component={Select}
            name="action"
            label={t('communicationChannels.postbackModal.action')}
            required={true}
          >
            <MenuItem
              value={PostbackDto.action.POST}
              data-cy="postbackMethodItem"
            >
              {PostbackDto.action.POST}
            </MenuItem>
            <MenuItem
              value={PostbackDto.action.GET}
              data-cy="postbackMethodItem"
            >
              {PostbackDto.action.GET}
            </MenuItem>
          </Field>
        </FormControl>
        <FormControlLabel
          control={<Field component={Switch} type="checkbox" name="notify" />}
          label={t('communicationChannels.active')}
          labelPlacement="end"
          sx={{ width: 1 / 5 }}
          data-cy="postbackActiveCheckbox"
        />
      </Stack>
      <Stack spacing={1}>
        <>
          <Typography variant="h6" component="h1">
            {t('communicationChannels.postbackModal.postbackParams')}
          </Typography>
          <GridTable
            colDefs={postbackParamColumns}
            errors={errors.postbackParams as unknown as Record<string, string>}
            data={values.postbackParams}
            translationPrefix={'communicationChannels.postbackModal'}
            onCreate={onCreatePostbackParam}
            hideResetFilter={true}
            enablePagination={false}
            onRowEditingStopped={onUpdatePostbackParam}
            onCellValueChanged={onCellValueChanged}
            actions={postbackParamActions}
            suppressNoRowsOverlay={true}
          />
        </>
      </Stack>
      <Stack spacing={2} direction="row">
        <FormikChipMultiSelect
          label={t('communicationChannels.postbackModal.monitoredStatesLabel')}
          required={true}
          fieldName="monitoredStates"
          data={MONITORED_STATES}
          translationPrefix="communicationChannels.postbackModal.monitoredStates"
        />
      </Stack>
    </>
  );
};
