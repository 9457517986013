/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiCollectionDataResponseOfCommunicationChannelDto } from '../models/ApiCollectionDataResponseOfCommunicationChannelDto';
import type { ApiCollectionDataResponseOfKeyValueDto } from '../models/ApiCollectionDataResponseOfKeyValueDto';
import type { ApiCollectionDataResponseOfUtmParamDto } from '../models/ApiCollectionDataResponseOfUtmParamDto';
import type { ApiDataResponseOfCommunicationChannelDto } from '../models/ApiDataResponseOfCommunicationChannelDto';
import type { ApiDataResponseOfPostbackDto } from '../models/ApiDataResponseOfPostbackDto';
import type { ApiResponseDto } from '../models/ApiResponseDto';
import type { CreateCommunicationChannelDto } from '../models/CreateCommunicationChannelDto';
import type { CreatePostbackDto } from '../models/CreatePostbackDto';
import type { UpdateCommunicationChannelDto } from '../models/UpdateCommunicationChannelDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CommunicationChannelsService {
  /**
   * @returns ApiCollectionDataResponseOfCommunicationChannelDto Data successfully retrieved.
   * @throws ApiError
   */
  public static findAll(): CancelablePromise<ApiCollectionDataResponseOfCommunicationChannelDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/communication-channels',
      errors: {
        401: `User was not authenticated.`,
        500: `Something went wrong.`,
      },
    });
  }

  /**
   * ### Error names for HTTP Status 400 Bad Request:<br>
   * + **Bad Request** - Validation failed. See 'messages' for further details.<br>
   * + **Invalid Affiliate Partner** - Affiliate partner does not exist or is invalid.<br>
   * + **Channel Code Not Unique** - Provided channel code is already being used.
   * @returns ApiDataResponseOfCommunicationChannelDto Data successfully retrieved.
   * @throws ApiError
   */
  public static create({
    requestBody,
  }: {
    requestBody: CreateCommunicationChannelDto;
  }): CancelablePromise<ApiDataResponseOfCommunicationChannelDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/communication-channels',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        401: `User was not authenticated.`,
        500: `Something went wrong.`,
      },
    });
  }

  /**
   * @returns ApiCollectionDataResponseOfKeyValueDto Data successfully retrieved.
   * @throws ApiError
   */
  public static getLookups({
    partnerId,
    active,
  }: {
    partnerId?: string;
    active?: boolean;
  }): CancelablePromise<ApiCollectionDataResponseOfKeyValueDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/communication-channels/lookups',
      query: {
        partnerId: partnerId,
        active: active,
      },
      errors: {
        401: `User was not authenticated.`,
        500: `Something went wrong.`,
      },
    });
  }

  /**
   * @returns ApiCollectionDataResponseOfUtmParamDto Data successfully retrieved.
   * @throws ApiError
   */
  public static getUtmParams(): CancelablePromise<ApiCollectionDataResponseOfUtmParamDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/communication-channels/utm-params',
      errors: {
        401: `User was not authenticated.`,
        500: `Something went wrong.`,
      },
    });
  }

  /**
   * @returns ApiDataResponseOfCommunicationChannelDto Data successfully retrieved.
   * @throws ApiError
   */
  public static findOne({
    id,
  }: {
    id: string;
  }): CancelablePromise<ApiDataResponseOfCommunicationChannelDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/communication-channels/{id}',
      path: {
        id: id,
      },
      errors: {
        401: `User was not authenticated.`,
        404: `Resource was not found.`,
        500: `Something went wrong.`,
      },
    });
  }

  /**
   * ### Error names for HTTP Status 400 Bad Request:<br>
   * + **Bad Request** - Validation failed. See 'messages' for further details.
   * @returns ApiDataResponseOfCommunicationChannelDto Data successfully retrieved.
   * @throws ApiError
   */
  public static update({
    id,
    requestBody,
  }: {
    id: string;
    requestBody: UpdateCommunicationChannelDto;
  }): CancelablePromise<ApiDataResponseOfCommunicationChannelDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v1/communication-channels/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        401: `User was not authenticated.`,
        404: `Resource was not found.`,
        500: `Something went wrong.`,
      },
    });
  }

  /**
   * ### Error names for HTTP Status 400 Bad Request:<br>
   * + **Conflict** - Resource cannot be deleted due to existing relations.
   * @returns ApiResponseDto Action was successful.
   * @throws ApiError
   */
  public static remove({
    id,
  }: {
    id: string;
  }): CancelablePromise<ApiResponseDto> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v1/communication-channels/{id}',
      path: {
        id: id,
      },
      errors: {
        400: `Validation failed.`,
        401: `User was not authenticated.`,
        404: `Resource was not found.`,
        500: `Something went wrong.`,
      },
    });
  }

  /**
   * @returns ApiDataResponseOfPostbackDto Data successfully retrieved.
   * @throws ApiError
   */
  public static findPostback({
    id,
  }: {
    id: string;
  }): CancelablePromise<ApiDataResponseOfPostbackDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/communication-channels/postback/{id}',
      path: {
        id: id,
      },
      errors: {
        401: `User was not authenticated.`,
        404: `Resource was not found.`,
        500: `Something went wrong.`,
      },
    });
  }

  /**
   * ### Error names for HTTP Status 400 Bad Request:<br>
   * + **Bad Request** - Validation failed. See 'messages' for further details.
   * @returns ApiDataResponseOfPostbackDto Data successfully retrieved.
   * @throws ApiError
   */
  public static setPostback({
    id,
    requestBody,
  }: {
    id: string;
    requestBody: CreatePostbackDto;
  }): CancelablePromise<ApiDataResponseOfPostbackDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v1/communication-channels/postback/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        401: `User was not authenticated.`,
        404: `Resource was not found.`,
        500: `Something went wrong.`,
      },
    });
  }

  /**
   * ### Error names for HTTP Status 400 Bad Request:<br>
   * + **Postback Test Connection Bad Request** - Postback configuration is incorrect<br>
   * + **Postback Test Connection Server Unreachable** - Destination server is not working or does not exist<br>
   * + **Postback Test Connection Server Unreachable** - Destination server is not working or does not exist
   * @returns ApiResponseDto Action was successful.
   * @throws ApiError
   */
  public static testConnection({
    id,
  }: {
    id: string;
  }): CancelablePromise<ApiResponseDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/communication-channels/postback/{id}/test',
      path: {
        id: id,
      },
      errors: {
        400: `Validation failed.`,
        401: `User was not authenticated.`,
        404: `Resource was not found.`,
        500: `Something went wrong.`,
      },
    });
  }
}
